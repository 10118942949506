//import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { ConfirmationService } from "primeng/api";
import { MenuItem } from "primeng/api";

import { SubSink } from "subsink";

import { ApiService }   from "../api.service";
import { StateService } from "../state.service";

import { STANDARDS, GENERIC_STANDARD }        from "./standards";
import { SPA_STATUS_LIST, SPA_STATUS_LOOKUP } from "./states";
import { SpaControlMask }                     from "./SpaControlMask";

import { FieldInfo }  from "./FieldInfo";
import { User }       from "../types/User";

const IMPORTANCE_LOOKUP = {
  "1": "Moderately",
  "2": "Very",
  "3": "Critical",
  "N/A": "Not Applicable",
  ""   : ""
};

@Component({
  selector: 'app-spa',
  templateUrl: './spa.component.html',
  styleUrls: ['./spa.component.css'],
  providers: [ConfirmationService]
})
export class SpaComponent implements OnInit {

  private user: any = User.empty;

  private spaControlMask = SpaControlMask.ALL_OFF_MASK;

  private subs = new SubSink();

  private readonly STANDARDS        = STANDARDS;
  private readonly GENERIC_STANDARD = GENERIC_STANDARD;
  private readonly SPA_STATUS_LIST  = SPA_STATUS_LIST;
  private readonly SPA_STATUS_LOOKUP  =  SPA_STATUS_LOOKUP;

  public readonly fieldInfo        = new FieldInfo();

  private hrMenuItems: Array<MenuItem> = [];

  spaId:  number; 
  spa:    any = {};

  // flags to control visibility of editing dialogs/controls
  textDialogDisplay:      boolean = false;
  scoreDialogDisplay:     boolean = false;
  jdDialogDisplay:        boolean = false;
  periodEditDisplay:      boolean = false;
  supervisorDialogDisplay:boolean = false;

  /** the information on the current rating being edited (if any) */
  scoreEditInfo = {
    scoreType   : "cat",
    scoreId     : 999,
    title       : "some title",
    description : " some description of something ",
    importance  : "",
    rating      : "",
    standard    : { scoreInfo: []},
    ratingBeingEdited: undefined,
  };

  /** the information on the text field being edited (if any) */
  textEditInfo = {
    fieldTitle: "",
    fieldname : "",
    fieldValue: "",
  };

  //periodEditInfo = [];
  periodEditInfo = {
    periodStart: undefined,
    periodEnd  : undefined,
  };

  private loadErrorInfo = {
    hasError: false,
    errorMessage: "",
  };

  constructor(
    private api: ApiService, 
    private route: ActivatedRoute,
    private state: StateService,
    private titleService: Title,
    private confirmationService: ConfirmationService ) { 
      this.titleService.setTitle("UCSD Staff Performace Appraisals - Individual Appraisal");
    }


  lookupImportance(anImportanceCode) {
    const lookup = IMPORTANCE_LOOKUP[anImportanceCode];
    return lookup ? lookup : "";
  }

  private loadSpa() {
    this.api.getSpa(this.spaId).subscribe(
      spa => { 
        console.log("got ", spa);
        this.loadErrorInfo = { hasError: false, errorMessage: "" };
        this.spa = spa;
        this.buildMenuItems();
        this.setControlVisibility();
        this.clearTextEditInfo();
      },
      error => {
        console.log("error loading spa: ", error);
        const eMessage = `Could not load SPA: ${error.statusText}`;
        this.loadErrorInfo = { hasError: true, errorMessage: eMessage};
      }
    );
  }

  private getDefaultNextHrAction() { // FIXME -- should be typed
    const curStatusInfo = SPA_STATUS_LOOKUP[this.spa.evalStatus];
    const nextCode = curStatusInfo.defaultHrNext;
    return nextCode ? SPA_STATUS_LOOKUP[nextCode] : undefined;
  }

  /**
  * building the menu items from the current state of the SPA
  * (mostly knowing what to disable is the dyanmic part)
  */

  private buildMenuItems() {
    const curStatusInfo = SPA_STATUS_LOOKUP[this.spa.evalStatus];
    const stateItems = SPA_STATUS_LIST.map(
      s => {
        return {
          label: s.name,
          disabled: ! curStatusInfo.hrNext.includes(s.stateCode),
          command: () => this.changeState(s.stateCode),
        };
      }
    );
    this.hrMenuItems = [
      {
        label: "All Status Changes",
        items: stateItems,
      },
      {
        label: "Change SPA",
        items: [
          { label: "Change Supervisor", 
            command: () => this.changeSupervisorDialog() },
          { label: "Change JD", 
            command:() => this.changeJdDialog() },
          { separator: true },
          { label: "Model A", 
            disabled: ! this.spa.minimal,
            command:() => this.changeModelBStatus(false) },
          { label: "Model B", 
            disabled: this.spa.minimal,
            command:() => this.changeModelBStatus(true) },
          { separator: true },
          { label: "Add Supervisor Categories",
            disabled: this.spa.cats.length > 11,
            command: () => this.changeSupCats("add")
          },
          { label: "Remove Supervisor Categories",
            disabled: this.spa.cats.length < 11,
            command: () => this.changeSupCats("remove")
          },
          { separator: true },
          { label: "Release",
            disabled: this.spa.hrStatus == "R",
            command: () => this.changeReleaseState("R")
          },
          { label: "Un-Release",
            disabled: ! (this.spa.hrStatus == "R"),
            command: () => this.changeReleaseState("")
          },
          { separator: true },
          { label: "Edit Period Covered",
            command: () => this.startPeriodEdits()
          },
          { separator: true },
          { label: "Unfinalize Self Appraisal",
            disabled: ! (this.spa.selfEvalStatus == 'C'),
            command: () => this.setSelfAppraisalStatus('')
          },
          { label: "Unfinalize Employee Comments ",
            disabled: ! (this.spa.employeeCommentStatus == 'C'),
            command: () => this.setEmployeeCommentStatus('')
          },
        ]
      },
    ];
  }

  ngOnInit() {
    this.subs.sink = this.state.getCurrentUser().subscribe( 
      user => { 
        this.user = user;
        this.setControlVisibility();
      },
      error => console.log("error getting user in spa ", error)
    );

    this.subs.sink = this.route.params.subscribe( params => {
      this.spaId = +params["id"];
      this.loadSpa();
    });
    
  }

  ngOnDestroy() { this.subs.unsubscribe(); }

  /** clear the current editing info */
  private clearScoreEditInfo() {
    this.scoreEditInfo.scoreType    = "";
    this.scoreEditInfo.scoreId      = -1;
    this.scoreEditInfo.title        = "unset title";
    this.scoreEditInfo.description  = " unset description ";
    this.scoreEditInfo.importance   = "";
    this.scoreEditInfo.rating       = "";
    this.scoreEditInfo.standard     = { scoreInfo: []};
    this.scoreEditInfo.ratingBeingEdited = undefined;
  }

  /**
  * fill in the current editing info (this.scoreEditInfo) and then
  * set the dialog display flag (this.scoreDialogDisplay) to true
  * which causes the primeng dialog to display
  */
  showScoreDialog(scoreType: string, scoreNumber: number, ratingBeingEdited) {

    console.log(`ST: ${scoreType}, SN: ${scoreNumber}`);
    console.log("ratingBeingEdited: ", ratingBeingEdited);

    if ( scoreType === "FUN" )      { // editing a function
      this.scoreEditInfo.description  = "Scoring function";
      this.scoreEditInfo.title        = "WHAT IS THE TITLE";
      this.scoreEditInfo.standard     = this.GENERIC_STANDARD;
      this.scoreEditInfo["selected"]     = this.GENERIC_STANDARD[1];
    } else if ( scoreType == "CAT" ) { // editing a category
      const std = this.STANDARDS[scoreNumber];
      this.scoreEditInfo.description  = std.category_description;
      this.scoreEditInfo.title        = std.category_title;
      this.scoreEditInfo.standard     = std;
    } else if ( scoreType == "OVER" ) { // editing the overall rating
      this.scoreEditInfo.description  = "Overall Rating";
      this.scoreEditInfo.title        = "WHAT IS THE TITLE";
      this.scoreEditInfo.standard     = this.GENERIC_STANDARD;
    } else {
      console.log(`bogus showScoreDialog scoreType ${scoreType}`);
    }

    this.scoreEditInfo.scoreType          = scoreType;

    this.scoreEditInfo.ratingBeingEdited  = ratingBeingEdited;

    if ( this.scoreEditInfo.ratingBeingEdited ) {
      this.scoreEditInfo.rating             = ratingBeingEdited.rating;
      this.scoreEditInfo.importance         = ratingBeingEdited.importance;
    } else {
      this.scoreEditInfo.rating             = this.spa.overallRating;
    }

    this.scoreDialogDisplay               = true;
  }

  /** clear the current score editing info and cause the dialog to undisplay */
  cancelScoreDialog() {
    this.scoreDialogDisplay = false;
    this.clearScoreEditInfo();
  }

  // Begin period edit related functions

  savePeriodEdits() {
    console.log("period edit done ", this.periodEditInfo);
    this.api.updatePeriod(this.spa.id, this.periodEditInfo.periodStart, 
      this.periodEditInfo.periodEnd).subscribe( 
      result => {
        this.periodEditDisplay = false;
        this.loadSpa();
      }, 
      error => console.log("saving ", this.periodEditInfo, "ERROR ", error)
    );
  }

  // show the period edit controls
  startPeriodEdits() {
    // FIXME -- should really mask rest of interface
    this.periodEditDisplay = true;
    this.periodEditInfo.periodStart = new Date(Date.parse(this.spa.periodStart));
    this.periodEditInfo.periodEnd   = new Date(Date.parse(this.spa.periodEnd));

  }

  // cancel edit and hide editor
  cancelPeriodEdits() {
    this.periodEditInfo.periodStart = new Date();
    this.periodEditInfo.periodEnd   = new Date();
    this.periodEditDisplay = false;
  }


  // End period edit related functions

  private saveFun() {
    this.api.updateFunction(
      this.spa.id,
      this.scoreEditInfo.ratingBeingEdited.id,
      this.scoreEditInfo.importance,
      this.scoreEditInfo.rating).subscribe( result => {
          console.log("saved function ", this.scoreEditInfo);
          this.scoreDialogDisplay = false;
          //FIXME -- should clear score dialog?
          this.loadSpa();
        },
        err => console.log("saving ", this.scoreEditInfo, "ERROR ", err)
      );
  }

  private saveCat() {
    this.api.updateCategory(
      this.spa.id,
      this.scoreEditInfo.ratingBeingEdited.categoryNum,
      this.scoreEditInfo.importance,
      this.scoreEditInfo.rating).subscribe( result => {
          console.log("saved cat ", this.scoreEditInfo);
          this.scoreDialogDisplay = false;
          //FIXME -- should clear score dialog?
          this.loadSpa();
        },
        err => console.log("saving ", this.scoreEditInfo, "ERROR ", err)
      );
  }

  /**
  * User inititated change in supervisor categories
  * FIXME -- should warn if some have scores and we are removing
  */
  private changeSupCats(aChange: "add" | "remove") {
    this.api.changeSupCategories(this.spa.id, aChange).subscribe( 
      result => this.loadSpa(),
      error  => console.log(`sup cat change ${aChange} error:`, error)
    );
  }

  private saveOverall() {
    this.api.updateOverall(
      this.spa.id, this.scoreEditInfo.rating).subscribe( result => {
          console.log("saved overall ", this.scoreEditInfo);
          this.scoreDialogDisplay = false;
          //FIXME -- should clear score dialog?
          this.loadSpa();
        },
        err => console.log("saving ", this.scoreEditInfo, "ERROR ", err)
      );
  }

  changeReleaseState(aState) {
    console.log("change release state called ", aState);
    this.api.updateTextField(this.spa.id, "hrStatus", aState).subscribe(
      result => {
          console.log("saved hrStatus ", aState);
          this.loadSpa();
      },
      err => console.log("changing release state ERROR ", err)
    );

  }

  setSelfAppraisalStatus(aStatus) {
    console.log("finalizing self appraisal");
    this.api.setSelfEvalStatus(this.spa.id, aStatus).subscribe(
      result => {
          console.log("updated self appraisal status", result);
          this.loadSpa();
      }, 
      err => console.log("finalizing self appraisal ERROR ", err)
    );
  }

  setEmployeeCommentStatus(aStatus) {
    console.log("finalizing employee Comments");
    this.api.setEmployeeCommentStatus(this.spa.id, aStatus).subscribe(
      result => {
          console.log("updated employee comment status", result);
          this.loadSpa();
      }, 
      err => console.log("finalizing employee comments ERROR ", err)
    );
  }

  changeModelBStatus(aBooleanFlag) {
    console.log("change model B flag called ", aBooleanFlag);
    this.api.updateTextField(this.spa.id, "minimal", aBooleanFlag).subscribe(
      result => {
          console.log("saved minimal ", aBooleanFlag);
          this.loadSpa();
      },
      err => console.log("changing minimal state ERROR ", err)
    );

  }

  changeState(newState) {
    console.log("change state to " + newState);
    this.api.setStatus(this.spa.id, newState).subscribe(
      result => {
          console.log("changed state ", result);
          this.loadSpa();
      },
      err => console.log("changing state ERROR ", err)
    );
  }

  saveScoreDialog(importance, rating) {
    console.log(`ss dialoge imp: ${importance} rating: ${rating}`);
    console.log("scoreEditInfo", this.scoreEditInfo);
    this.scoreEditInfo.rating = rating;
    this.scoreEditInfo.importance = importance;
    if ( this.scoreEditInfo.scoreType == "FUN" ) {
      this.saveFun();
    } else if ( this.scoreEditInfo.scoreType == "CAT" ) {
      this.saveCat();
    } else if ( this.scoreEditInfo.scoreType == "OVER" ) {
      this.saveOverall();
    } else {
      console.log(
        `unimplemented score save for type ${this.scoreEditInfo.scoreType}`);
      this.scoreDialogDisplay = false;
    }
    console.log(this.scoreEditInfo);
  }

  /** set the text edit info and show text edit dialog */
  showTextDialog(fieldname) {
    //this.textEditInfo.fieldname   = fieldname;
    //this.textEditInfo.fieldValue  = this.spa[fieldname];

    this.textEditInfo = { 
      fieldname: fieldname, 
      fieldValue: this.spa[fieldname],
      fieldTitle: this.fieldInfo.lookup(fieldname).title
    };
    //this.htmlEditor.textBuffer =   this.spa[fieldName];
    this.textDialogDisplay        = true;
    //console.log("textEditInfo in show Text dialog", this.textEditInfo);
  }

  /**
  * clear the text edit (html-edit) info.  This makes sure that the 
  * html-edit component doesn't re-open with old/stale data 
  */
  clearTextEditInfo() {
    //this.textEditInfo.fieldname = "";
    //this.textEditInfo.fieldValue = "";
    this.textEditInfo = { fieldname: "", fieldValue: "", fieldTitle: ""};
    //console.log("after clear of textEditInfo: ", this.textEditInfo);
  }

  /**
  * close the text (html-edit) dialog and clear the text edit info
  */
  closeTextDialogAndClearEditInfo() {
    this.textDialogDisplay = false;
    this.clearTextEditInfo();
  }

/*
  saveTextDialog(newText) {
    console.log("saveTextDialog called with ", newText);
    this.textEditInfo.fieldValue = newText;

    console.log("in save text dialog with edit info ", this.textEditInfo);
    this.api.updateTextField(this.spaId, this.textEditInfo.fieldName,
      this.textEditInfo.fieldValue).subscribe( saveResult => {
        console.log("save result: ", saveResult);
        this.loadSpa();
      });
    this.closeTextDialogAndClearEditInfo();
  }
  */

  /**
  * Save the updated field's new html data to the database,
  * reload the SPA from the backend, close the html-edit dialog
  * and reset the edit info to blank.
  */
  private saveHtmlField(fieldname, newText) {
    console.log("saveTextDialog called newText", newText);
    console.log("saveTextDialog called fieldname ", fieldname);

    console.log("in save text dialog with edit info ", this.textEditInfo);
    this.api.updateTextField(this.spaId, fieldname, newText).subscribe( 
      saveResult => {
        console.log("save result: ", saveResult);
        this.loadSpa();
      });
    this.closeTextDialogAndClearEditInfo();
  }

  hideTextDialog() {
    console.log("about to call confirmation");
    this.confirmationService.confirm({
      message: "really want to close this dialog?",
      accept: () => {
        console.log("in accept for confirmation");
        console.log("confirm ended");
        this.closeTextDialogAndClearEditInfo();
      },
      reject: () => {
        console.log("confirmation rejected");
      }
    });
  }

  /**
  * process an html-edit update event.  Either a field has been
  * updated and we need to save it to the database or the edit has
  * been cancelled.  This method is called only by the event emitter
  * binding on the Output() from the html-edit component.
  */
  htmlEditUpdate(hupdateData) {
    console.log("hupdateData ", hupdateData);
    if ( hupdateData.action == "CANCELLED") {
      this.hideTextDialog();
    } else if ( hupdateData.action == "SAVE" ) {
      console.log("updating with ", hupdateData);
      this.saveHtmlField(hupdateData.textFieldname, hupdateData.textValue);
    } else {
      console.log("invalid action for hupdateData ", hupdateData);
    }
  }

/*
  controlVisible(aControlType): boolean {
    console.log("WARNING -- not checking control types yet: ", aControlType);
    return true;
  }
  */

  autofill() {
    console.log("autofilling call from spa");
    this.api.autofill(this.spa.id).subscribe(
      result => {
          console.log("autofill result", result);
          this.loadSpa();
      },
      err => console.log("autofill ERROR ", err)
    );
  }

  sign() {
    console.log("signing call from spa");
    this.api.sign(this.spa.id).subscribe(
      result => {
          console.log("signed result", result);
          this.loadSpa();
      },
      err => console.log("signing ERROR ", err)
    );
  }

  getStatusFromCode(aCode) {
    return this.SPA_STATUS_LOOKUP[aCode];
  }

  getNextSupStatus() {
    const curStatus = this.getCurrentStatus();
    return this.getStatusFromCode(curStatus.supNext);
  }

  getCurrentStatus() {
    return this.getStatusFromCode(this.spa.evalStatus);
  }

  /**
  * set the mask for this user and spa so that only usuable controls
  * are visible in the form.
  */
  setControlVisibility() {
    if ( this.user && this.spa ) {
      this.spaControlMask = new SpaControlMask(this.spa, this.user);
    } else {
      console.log("can't set control visibility - user or spa false");
    }
    console.log("new spaControlMask", this.spaControlMask);
  }

  changeSupervisorDialog() {
    console.log("showing supervisor display dialog");
    this.supervisorDialogDisplay = true;
  }

  supervisorSelectAction(action) {
    console.log(`spa supervisorSelectAction(e) got, `, action);
    if ( action.action == "CANCELLED") {
      this.supervisorDialogDisplay = false;
      return;
    }
    this.api.updateSupervisor(this.spa.id, action.empno).subscribe(
      result => {
        console.log("result from update supervisor ", result);
        this.loadSpa();
      },
      error  => console.log("error changing supervisor ", error)
    );
    this.supervisorDialogDisplay = false;
  }

  changeJdDialog() {
    console.log("showing jd select dialog");
    //console.log("display var before ", this.jdDialogDisplay);
    this.jdDialogDisplay = true;
    //console.log("display var after ", this.jdDialogDisplay);
  }

  jdSelectAction(action) {
    console.log(`spa jdSelectAction(e) got, `, action);
    this.jdDialogDisplay = false;

    if ( action.action == "SELECTED" ) { // FIXME -- better type safety??
      console.log("updating jd from spa component");
      const newJdInfo = action.jd.JDVersionID;
      this.api.updateJd(this.spa.id, newJdInfo).subscribe(
        result => {
          console.log("result from update jd ", result);
          this.loadSpa();
        },
        error  => console.log("error changing jd ", error)
      );
    } else {
      console.log("updating jd cancel action recd by spa component");
    }
  }

 spaIsFilledOut() {
  if ( ! this.spa ) {
    return false;
  }
  const catsNotFilledOut = this.spa.cats
    .filter(c => c.importance === "" || c.rating === "");
  let catsFilledOut = catsNotFilledOut.length === 0;

  const functionsNotFilledOut = this.spa.functions
    .filter(f => f.importance === "" || f.rating === "");
  let functionsFilledOut = functionsNotFilledOut.length === 0;

  // if it's a Model B then no need to fill out these sections 
  // in fact the user literally can't fill them out.
  if ( this.spa.minimal ) {
    functionsFilledOut = true;
    catsFilledOut     = true;
  }

    const textIsFilledOut = this.spa.supportComments && 
      this.spa.futurePlans && 
      this.spa.futurePlans.length > 5 && 
      this.spa.supportComments.length > 5;

  const overallScoreFilledOut = this.spa.overallRating != "";
  //console.log("cats: " + catsFilledOut);
  //console.log("functs: " + functionsFilledOut);
  //console.log("text: " + textIsFilledOut);
  //console.log("over: " + overallScoreFilledOut);

  return catsFilledOut && functionsFilledOut && textIsFilledOut && overallScoreFilledOut;
  };

}
